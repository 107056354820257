
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/





/*
|--------------------------------------------------------------------------
| Gestion des cookies d'acquisition
|--------------------------------------------------------------------------
|
*/


function getUrlVars() {
	var vars = {};
	var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
		vars[key] = value;
	});
	return vars;
}
function removeLocalhost(cookieText) {
	//bug étrange, si on a le mot 'localhost' ou '127.0.0.1' dans un cookie, on a une erreur cloudfront 403 sur systeme.io
	return cookieText = cookieText.replace('localhost', 'local-host')
}
function setCookie(name, value, days = 7) {
	var d = new Date;
	d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
	value = removeLocalhost(value)
	document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}
function setJsonCookie(name, obj, days = 7) {
	let cookieText = null
	try {
		cookieText = JSON.stringify(obj)
		cookieText = removeLocalhost(cookieText)
	} catch (e) {
		console.log(e)
	}
	setCookie(name, cookieText, days)
}
function getCookie(name) {
	var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
	return v ? v[2] : null;
}
function getCookieAsJson(name) {
	const cookieText = getCookie(name)
	let cookieJSON = null
	if (cookieText) {
		try {
			cookieJSON = JSON.parse(cookieText)
		} catch (e) {
			console.log(e)
		}
	}
	return cookieJSON
}
function addToJsonCookie(cookieName, values) {
	let acq = getCookieAsJson(cookieName)
	if (acq == null) acq = {}
	acq = { ...acq, ...values }
	setJsonCookie(cookieName, acq)
}

document.addEventListener("DOMContentLoaded", function () {

	//store acquisition details if any are passed via URL : strategy = last non-null wins (because we want to know the one who triggered the purchase)

	let acq = getUrlVars()["van_acq"]
	if (acq) {
		try {
			acq = JSON.parse(decodeURIComponent((acq)))
			addToJsonCookie("van_acq", acq)
		} catch (e) {
			console.log(e)
		}
	}

	//affiliation
	let affId = getUrlVars()["aid"]; if (affId) addToJsonCookie("van_acq", { aid: decodeURIComponent(affId) })

	//utm params
	const urlParams = getUrlVars()
	Object.keys(urlParams).filter(key => key.startsWith('utm_')).forEach(key => {
		const value = urlParams[key]
		console.log(`adding ${key}=${value}`);
		if (value) addToJsonCookie("van_acq", { [key]: decodeURIComponent(value) })
	})
	//utm_source=lcdc-contribute&utm_medium=social&utm_campaign=soutien_lcdc&utm_term=z&utm_content=z

	//store referrer : strategy = first non-null wins, because only the first referrer is the previous external website. If we put it in van_acq and the user refresh the page, we lose the value

	const referrerFromCookie = getCookie("van_ref")
	const referrerFromUrl = getUrlVars()["van_ref"]
	const referrerFromDom = document.referrer
	if (referrerFromCookie) {
		console.log("referrer is already defined, don't do anything : " + referrerFromCookie)
	} else {
		if (referrerFromUrl) {
			console.log("using the referrer passed from url : " + referrerFromUrl)
			setCookie("van_ref", referrerFromUrl)
		} else {
			if (referrerFromDom) {
				console.log("using the referrer document.referrer : " + referrerFromDom)
				setCookie("van_ref", referrerFromDom)
			} else {
				console.log("no referrer neither in cookie, url param nor in document.referrer => do nothing")
			}
		}
	}

	console.log("The van_acq cookie is ", getCookieAsJson("van_acq"))
	console.log("The van_ref cookie is ", getCookie("van_ref"))

});






/*
|--------------------------------------------------------------------------
| Fonctionnalités diverses
|--------------------------------------------------------------------------
|
*/



$(function () {

	const btnStep1 = document.getElementById('btnStep1')
	const formStep1 = document.getElementById('formStep1')
	const formStep1_submitButton = document.getElementById('formStep1_submitButton')
	const formStep1_loading = document.getElementById('formStep1_loading')

	if (btnStep1) {
		btnStep1.addEventListener('click', e => {
			e.preventDefault()
			document.getElementById('formStep1').style.display = 'block'
			document.getElementById('btnStep1').style.display = 'none'
		})
	}

	if (formStep1_submitButton) {
		formStep1_submitButton.addEventListener('click', e => {
			submitTrialForm()
		})
	}

	if (formStep1) {
		formStep1.addEventListener('submit', e => {
			e.preventDefault()
			submitTrialForm()
		})
	}

	async function submitTrialForm() {
		const formStep1_email = document.getElementById('email')
		const email = formStep1_email.value
		if (isValidEmail(email)) {
			console.log("email=" + email)
			formStep1_submitButton.style.display = 'none'
			formStep1_loading.style.display = 'block'
			const res = await fetch('/automation/newtrial', { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email: email }) })
			const json = await res.json()
			console.log(json)
			if (json.error) {
				if (/^User .* already exists$/.test(json.error)) {
					formStep1.innerHTML = `
					<div class="alert alert-danger" role="alert" style="word-break:break-word;font-size:0.8em">
						Vous avez déjà utilisé Trouver La Bonne Affaire.<br/>
						L'offre de découverte gratuite s'adresse uniquement aux personnes n'ayant jamais utilisé le logiciel.<br/>
						Si le logiciel vous a plu et que vous souhaitez vous abonner, <a href='/pricing.html' style="color:blue;text-decoration:underline">cliquez ici pour obtenir un compte</a>.
					</div>`
				} else if (/^.* is not a valid email address.$/.test(json.error)) {
					formStep1.innerHTML = `
					<div class="alert alert-danger" role="alert" style="word-break:break-word;font-size:0.8em">
						Cette adresse email n'est pas valide.
					</div>`
				} else if (/^ck_present$/.test(json.error)) {
					formStep1.innerHTML = `
					<div class="alert alert-danger" role="alert" style="word-break:break-word;font-size:0.8em">
						Vous avez déjà obtenu un compte de test gratuit.<br/>
						La possibilité de tester gratuitement le logiciel a pour but de vous donner un aperçu de son utilité, mais n'a pas vocation à être renouvelée plusieurs fois.<br/>
						Si le logiciel vous a plu et que vous souhaitez vous abonner, <a href='/pricing.html' style="color:blue;text-decoration:underline">cliquez ici pour obtenir un compte</a>.
					</div>`
				} else {
					formStep1.innerHTML = `
					<div class="alert alert-danger" role="alert" style="word-break:break-word;font-size:0.8em">
						Votre demande a généré une erreur.<br/>N'hésitez pas à me contacter sur Messenger (lien 'Contact' dans le menu du site) avec un copier/coller ou une capture d'écran de l'erreur suivante afin que je puisse vous débloquer :<br/><br/>${json.error}
					</div>`
				}
			} else {
				formStep1.innerHTML = `
				<div class="alert alert-success" role="alert" style="word-break:break-word;font-size:0.8em">
					👍 Votre mot de passe vient de vous être envoyé sur ${email}. Si vous ne le trouvez pas, vérifiez bien dans votre dossier spam.
				</div>`
			}
			formStep1_loading.style.display = 'none'
			formStep1_submitButton.style.display = 'block'
			document.getElementById('formTop').scrollIntoView({ behavior: 'smooth', block: 'start' })

		} else {
			alert("Veuillez saisir une adresse email valide.")
		}
	}

	function isValidEmail(email) {
		var re = /\S+@\S+\.\S+/;
		return re.test(email);
	}


	//validation de code promo
	const promocodeform = document.getElementById('promocodeform')
	const promocodetext = document.getElementById('promocodetext')
	const promocodestatus = document.getElementById('promocodestatus')
	promocodeform && promocodeform.addEventListener('submit', e => {
		e.preventDefault()
		promocodestatus.innerText = "Validation du code en cours..."
		promocodestatus.classList.remove('d-none')
		setTimeout(() => {
			promoCodeInput = promocodetext.value.toLowerCase()
			const promocodes = [
				{
					code: 'immo101',
					message: "✅ Code valide. Redirection vers l'offre IMMO 101...",
					redirect: '/pricing_offer_immo_101.html'
				},
				{
					code: 'nils52',
					message: "✅ Code valide. Redirection vers l'offre...",
					redirect: '/pricing_offer_nils_gallet.html'
				},
				{
					code: '16jul',
					message: "✅ Code valide. Redirection vers l'offre du 15 et 16 juillet...",
					redirect: '/pricing_offer_16jul.html',
					validity: new Date('2020-07-16T21:59:59.000Z') //ATTENTION DATE EN GMT !
				},
			]
			const matchingPromoCode = promocodes.find(promo => promo.code.toLowerCase() == promoCodeInput.toLowerCase())
			if (matchingPromoCode) {
				if (matchingPromoCode.validity != null && matchingPromoCode.validity < new Date()) {
					promocodestatus.innerText = "😢 Ce code promo a expiré"
				} else {
					promocodestatus.innerText = matchingPromoCode.message
					addToJsonCookie("van_acq", { promocode: promoCodeInput })
					setTimeout(() => {
						window.location.href = matchingPromoCode.redirect
					}, 1000);
				}
			} else {
				promocodestatus.innerText = "😢 Ce code promo n'est pas valable"
			}
		}, 1000);
	})


	//ouvre la page de paiement SIO en envoyant via l'URL toutes les infos d'acquisition présentes dans le cookie van_acq
	const buttons = document.querySelectorAll('.btn-go-to-sio-with-acq')
	for (const button of buttons) {
		button.addEventListener('click', e => {
			e.preventDefault();
			const targetUrl = `${e.target.dataset.url}?van_ref=${encrypt(getCookie("van_ref"))}&van_acq=${encrypt(JSON.stringify(getCookieAsJson("van_acq")))}`
			window.location.href = targetUrl
		})
	}

	function encrypt(string) {
		return CryptoJS.AES.encrypt(string, '4i}#q&6t9g|>6{8@C|Z<2prD7vg>%3').toString();
	}

});


// FACEBOOK PIXEL
!function (f, b, e, v, n, t, s) {
	if (f.fbq) return; n = f.fbq = function () {
		n.callMethod ?
			n.callMethod.apply(n, arguments) : n.queue.push(arguments)
	};
	if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
	n.queue = []; t = b.createElement(e); t.async = !0;
	t.src = v; s = b.getElementsByTagName(e)[0];
	s.parentNode.insertBefore(t, s)
}(window, document, 'script',
	'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '418028705785020');
fbq('track', 'PageView');

